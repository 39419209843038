module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-flexsearch/gatsby-browser.js'),
      options: {"plugins":[],"languages":["de"],"type":"MarkdownRemark","fields":[{"name":"id","indexed":false,"resolver":"id","store":true},{"name":"html","indexed":true,"resolver":"internal.content","attributes":{"encode":"balance","tokenize":"strict","threshold":0,"resolution":3,"depth":3},"store":true},{"name":"title","indexed":true,"resolver":"frontmatter.title","attributes":{"encode":"extra","tokenize":"full","threshold":1,"resolution":3},"store":true},{"name":"description","indexed":true,"resolver":"frontmatter.description","attributes":{"encode":"icase","tokenize":"forward","threshold":2,"depth":3},"store":true},{"name":"type","indexed":false,"resolver":"frontmatter.type","store":true},{"name":"slug","indexed":false,"resolver":"fields.slug","store":true},{"name":"layout","indexed":false,"resolver":"frontmatter.layout","store":true}]},
    }]
